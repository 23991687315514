/** GraphQL Mutation that sets a shipping address on received cart. */
export default `
  mutation setSimpleShippingAddressesOnCart($input: SetSimpleShippingAddressesOnCartInput) {
    setSimpleShippingAddressesOnCart(input: $input) {
      cart {
        prices {
          tax_amount
          subtotal_excluding_tax {
            value
          }
          subtotal_including_tax {
            value
          }
          grand_total {
            value
          }
          fees {
            value
          }
        }
        shipping_addresses {
          available_shipping_methods {
            amount {
              currency
              value
            }
            available
            service_code
            carrier_code
            carrier_title
            error_message
            method_code
            method_title
            price_excl_tax {
              currency
              value
            }
            price_incl_tax {
              currency
              value
            }
            additional_data
          }
        }
      }
    }
  }
`;
