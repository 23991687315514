import { readonly, ref, computed, useContext } from '@nuxtjs/composition-api';
import deliveryDataForAllShippings from '~/diptyqueTheme/customQueries/magento/deliveryDataForAllShippings';
import deliveryBannerDataQuery from '~/diptyqueTheme/customQueries/magento/deliveryBannerData';
import { useApi, useConfig } from '~/composables';
import { Logger } from '~/helpers/logger';
import {
  AllDeliveryMethodsResponse,
  DeliveryMethodResponse,
  AllDeliveryMethodsParsedResponse,
  DeliveryMethodParsedResponse,
  UseNarVarInterface,
  EstimatedDeliveryDates
} from './types';

export function useNarVar(): UseNarVarInterface {
  const { app: { i18n } } = useContext();
  const { query } = useApi();
  const { config: storeConfig } = useConfig();
  const estimationDeliveryDates = ref<EstimatedDeliveryDates>({});
  const estimatedDeliveryDate = ref<string>('');
  const getDestinationCountry = computed<string>(() => storeConfig.value.general_country_default);
  const getOrderDate = computed<string>(() => new Date().toISOString().slice(0, 10));
  const getEstimatedDeliveryDates = async (
    cart_id: string,
    dest_country: string = getDestinationCountry.value,
    order_date: string = getOrderDate.value
  ): Promise<void> => {
    Logger.debug('useNarVar/getEstimatedDeliveryDates', [cart_id, dest_country, order_date]);
    try {
      const { data }: AllDeliveryMethodsResponse = await query(deliveryDataForAllShippings, {
        cart_id,
        dest_country,
        order_date
      });

      if (data) {
        if (data?.getDeliveryDataForAllShippings.length) {
          const parsedData: AllDeliveryMethodsParsedResponse =
            data?.getDeliveryDataForAllShippings && JSON.parse(data.getDeliveryDataForAllShippings);

          if (parsedData && parsedData.success) {
            const deliveryData = JSON.parse(parsedData.info);
            const deliveryMethods = Object.keys(deliveryData);
            if (deliveryMethods.length > 0) {
              deliveryMethods.forEach(method => {
                const methodCodes = Object.keys(deliveryData[method]);
                methodCodes.forEach(code => {
                  const [year, day, month] = deliveryData[method][code].split('-');
                  deliveryData[method][code] = ['en_us', 'fr_us'].includes(i18n.locale) ? deliveryData[method][code] : `${year}-${month}-${day}`;
                })
              })
            }
            estimationDeliveryDates.value = deliveryData;
          }
        }
      }
    } catch (err) {
      Logger.error('useNarVar/getEstimatedDeliveryDates', err);
      console.error(err);
    }
  };

  const getEstimatedDeliveryDate = async (
    cart_id?: string,
    dest_country: string = getDestinationCountry.value,
    order_date: string = getOrderDate.value
  ): Promise<void> => {
    Logger.debug('useNarVar/getEstimatedDeliveryDate', [cart_id, dest_country, order_date]);
    try {
      const { data }: DeliveryMethodResponse = await query(deliveryBannerDataQuery, {
        cart_id,
        dest_country,
        order_date
      });

      const parsedData: DeliveryMethodParsedResponse = data?.getEstimatedDeliveryData && JSON.parse(data.getEstimatedDeliveryData);
      if (parsedData && parsedData.success) {
        const [year, day, month] = parsedData.message.split('-');
        const date = ['en_us', 'fr_us'].includes(i18n.locale) ? parsedData.message : `${year}-${month}-${day}`;
        estimatedDeliveryDate.value = date;
      }
    } catch (err) {
      Logger.error('useNarVar/getEstimatedDeliveryDate', err);
      console.error(err);
    }
  };

  const getShippingMethodEstimatedDelivery = (
    carrierCode: string,
    serviceCode: string,
    methodTitle: string
  ): string | undefined => {
    let matchedDate;

    if (!estimationDeliveryDates.value) return;

    if (
      serviceCode &&
      carrierCode &&
      estimationDeliveryDates.value[carrierCode] &&
      estimationDeliveryDates.value[carrierCode][serviceCode]
    ) {
      // Matching using carrier_code and service_code, for Narvar methods
      matchedDate = estimationDeliveryDates.value[carrierCode][serviceCode];
    } else if (!matchedDate && methodTitle) {
      // Matching using method_title, for matrixrate
      const matchedKey = Object.keys(estimationDeliveryDates.value).find(
        (key) => methodTitle.toLowerCase().includes(key)
      );

      if (matchedKey) {
        matchedDate = estimationDeliveryDates.value[matchedKey][serviceCode];
      }
    }

    return matchedDate;
  };

  const formatDate = (date: string): string => {
    const [, month, day] = date.split('-');

    return `${day}.${month}`;
  };

  return {
    getEstimatedDeliveryDates,
    getEstimatedDeliveryDate,
    formatDate,
    estimatedDeliveryDate: readonly(estimatedDeliveryDate),
    estimationDeliveryDates: readonly(estimationDeliveryDates),
    getShippingMethodEstimatedDelivery
  };
}

export default useNarVar;
